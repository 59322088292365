import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { size } from 'lodash'
import { navigate } from 'gatsby'
import { Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  DashboardSkeleton,
  DesignDashboardTable,
  ErrorMessage,
  Layout,
  ListingDashboardTable,
  SEO,
} from 'components'
import { NOT_FOUND_ENDPOINT } from 'constants/apiUrls'
import {
  DEFAULT_LISTING_LIMITS,
  ERROR_FETCHING_LISTINGS,
  LISTING_STATUS,
} from 'constants/listingConstants'
import { DEFAULT_DESIGNS_LIMITS } from 'constants/modelConstants'
import { getListing } from 'services/ListingService'
import { fetchDesigns } from 'services/DesignService'
import { getAcceptedCompletedOfferByListingId } from 'services/OfferService'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '20px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  tab: {
    backgroundColor: theme.palette.text.white,
    '& .MuiTab-textColorInherit.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
    },
    '& .MuiTab-wrapper': {
      fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
}))
const OPTIONS = { LISTING_ACTIVE: 0, LISTING_COMPLETED: 1, DESIGNS: 2 }

const {
  APPROVED,
  AWAITING_APPROVAL,
  DELIVERED,
  IN_PROGRESS,
  REJECTED,
  SHIPPED,
  SUBMITTED,
  OFFER_ACCEPTED,
  OFFER_COMPLETED,
  OFFER_PENDING,
} = LISTING_STATUS

const DashboardPage = () => {
  const classes = useStyles()
  const mountedRef = useRef(true)
  const loggedUser = useSelector(state => state.userState.user)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasNext, setHasNext] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [latestElements, setLatestElements] = useState([])
  const [listings, setListings] = useState([])
  const [designs, setDesigns] = useState([])
  const [loading, setLoading] = useState(false)
  const [offers, setOffers] = useState([])
  const [value, setValue] = useState(0)

  const activeStatus = [
    APPROVED,
    AWAITING_APPROVAL,
    IN_PROGRESS,
    SHIPPED,
    SUBMITTED,
    OFFER_ACCEPTED,
    OFFER_COMPLETED,
    OFFER_PENDING,
  ]

  useEffect(() => {
    if (!loggedUser.isOperator) {
      navigate(NOT_FOUND_ENDPOINT)
    }
  }, [loggedUser])

  useEffect(() => {
    const getInitialListings = async () => {
      setLoading(true)
      setErrorMessage('')
      try {
        const { lastElement, listings } = await getListing(
          null,
          DEFAULT_LISTING_LIMITS[2],
          null,
          activeStatus
        )
        await getOffers(listings)
        setHasNext(size(listings) === DEFAULT_LISTING_LIMITS[2])
        setListings(listings)
        setLatestElements([lastElement])
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_LISTINGS)
      }
      setLoading(false)
    }
    if (
      loggedUser &&
      loggedUser.isOperator &&
      !size(listings) &&
      !size(latestElements) &&
      !loading &&
      mountedRef.current
    ) {
      getInitialListings()
    }
    return () => {
      mountedRef.current = false
    }
  }, [activeStatus, latestElements, listings, loading, loggedUser, value])

  const fetchListings = async ({
    statusOption = 0,
    limit = DEFAULT_LISTING_LIMITS[2],
    lastElement,
  }) => {
    let hasUpdated = false
    setIsFetching(true)
    setErrorMessage('')
    try {
      const listingStatus =
        statusOption === 0 ? activeStatus : [DELIVERED, REJECTED]
      const { lastElement: newLastElement, listings } = await getListing(
        null,
        limit,
        lastElement,
        listingStatus
      )
      setListings(listings)
      if (size(listings) > 0) {
        await getOffers(listings)
        hasUpdated = true
        setHasNext(size(listings) === limit)
        if (lastElement) {
          setLatestElements([...latestElements, newLastElement])
        } else {
          setLatestElements([newLastElement])
        }
      } else {
        setHasNext(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_LISTINGS)
    }
    setIsFetching(false)
    return hasUpdated
  }

  const nextPage = async (page, limit, fetchListings) => {
    const lastElement = latestElements[page - 1]
    return fetchListings({ statusOption: value, limit, lastElement })
  }

  const prevPage = async (page, limit, fetchListings) => {
    const lastElement = page > 0 ? latestElements[page - 1] : null
    latestElements.pop()
    latestElements.pop()
    setLatestElements(latestElements)
    fetchListings({ statusOption: value, limit, lastElement })
  }

  const fetchDesign = async ({
    limit = DEFAULT_DESIGNS_LIMITS[2],
    lastElement,
  }) => {
    let hasUpdated = false
    setIsFetching(true)
    setErrorMessage('')
    try {
      let { lastElement: newLastElement, designs } = await fetchDesigns(
        null,
        limit,
        lastElement
      )

      const currentDesigns = await Promise.all(
        designs.map(async design => {
          const designer = await getUserById(design.ownedBy.uid)
          return { ...design, designer }
        })
      )

      setDesigns(currentDesigns)
      if (size(designs) > 0) {
        hasUpdated = true
        setHasNext(size(designs) === limit)
        if (lastElement) {
          setLatestElements([...latestElements, newLastElement])
        } else {
          setLatestElements([newLastElement])
        }
      } else {
        setHasNext(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_LISTINGS)
    }
    setIsFetching(false)
    return hasUpdated
  }

  const getOffers = async listings => {
    try {
      if (size(listings)) {
        const offers = await Promise.all(
          listings.map(async listing => {
            let newOffer = {}
            if (listing.status !== SUBMITTED) {
              newOffer = await getAcceptedCompletedOfferByListingId(listing.id)
            } else {
              newOffer = {}
            }
            return newOffer
          })
        )
        setOffers(offers)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_LISTINGS)
    }
  }

  const handleTabChange = async (event, newValue) => {
    setValue(newValue)
    if (
      newValue === OPTIONS.LISTING_ACTIVE ||
      newValue === OPTIONS.LISTING_COMPLETED
    ) {
      await fetchListings({ statusOption: newValue })
    }
    if (newValue === OPTIONS.DESIGNS) {
      await fetchDesign({})
    }
  }

  return (
    <Layout>
      <SEO title="dashboard" />
      <ErrorMessage message={errorMessage} />
      {loading ? (
        <DashboardSkeleton />
      ) : (
        <div className={classes.container}>
          <h1>DASHBOARD</h1>
          <Tabs
            className={classes.tab}
            onChange={handleTabChange}
            value={value}
          >
            <Tab key={0} label="Active listings" />
            <Tab key={1} label="Completed listings" />
            <Tab key={2} label="Designs" />
          </Tabs>
          {value === 0 && (
            <ListingDashboardTable
              fetchListings={fetchListings}
              hasNext={hasNext}
              initRowsPerPage={DEFAULT_LISTING_LIMITS[2]}
              isFetching={isFetching}
              listings={listings}
              setOffers={setOffers}
              offers={offers}
              menuOption={0}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          )}
          {value === 1 && (
            <ListingDashboardTable
              fetchListings={fetchListings}
              hasNext={hasNext}
              initRowsPerPage={DEFAULT_LISTING_LIMITS[2]}
              isFetching={isFetching}
              listings={listings}
              setOffers={setOffers}
              offers={offers}
              menuOption={1}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          )}
          {value === 2 && listings && (
            <DesignDashboardTable
              designs={designs}
              fetchDesign={fetchDesign}
              hasNext={hasNext}
              initRowsPerPage={DEFAULT_DESIGNS_LIMITS[2]}
              isFetching={isFetching}
              menuOption={2}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          )}
        </div>
      )}
    </Layout>
  )
}

export default DashboardPage
